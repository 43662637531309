import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Switch, withRouter } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import { flowRight as compose, get } from 'lodash';
import { withCookies } from 'react-cookie';

import apolloClient from '../services/apolloClient';
import bootstrap from '../services/bootstrap';
import routes from '../services/routes';
import GenericLoader from '@joybird/joystagram-components/dist/esm/GenericLoader';
import {
  GET_ACCOUNT_INFO,
  GET_UJET_AUTH,
} from '@joybird/joystagram-components/dist/esm/queries';
import { markLuxPageNavigationStart } from '@joybird/joystagram-components/dist/esm/lux';
import getEnvValue from '@joybird/joystagram-components/dist/esm/getEnvValue';
import setHttpContextValue from '@joybird/joystagram-components/dist/esm/setHttpContextValue';
import withSetAuthTokenAndUser from '@joybird/joystagram-components/dist/esm/withSetAuthTokenAndUser';

import {
  displayBlockElement,
  hideElement,
  isClient,
  isIE,
  showElement,
} from './globals';
import IEMsg from '../components/IEMsg';
import PurgeUserCache from '../components/PurgeUserCache';
import ExposeHistory from '../components/ExposeHistory';
import getRouteInfo from './helpers/getRouteInfo';
import logPageViewEvent from './helpers/logPageViewEvent';
import getPageType from './helpers/getPageType';
import loadUJETScript from './helpers/loadUJETScript';
import { setCJAffiliateCookie } from '@joybird/joystagram-components/dist/esm/localStorage';
import poll from './helpers/poll';
import {
  setLuxPageLabel,
  LUX_PAGE_LABELS,
} from '@joybird/joystagram-components/dist/esm/lux';
import SkipToContentLink from '@joybird/joystagram-components/dist/esm/SkipToContentLink';
import initTalkativeCustomConfig from './helpers/initTalkativeCustomConfig';
import isUjetChatEnabled from './helpers/isUjetChatEnabled';
import isTalkativeChatEnabled from './helpers/isTalkativeChatEnabled';
import loadTalkativeScript from './helpers/loadTalkativeScript';
import disablePopups from './helpers/disablePopups';

const isRunningOnClient = isClient();

// Note: the @ujet/websdk-headless package uses JavaScript features that are not
// supported in the version of Node.js used by the current Consumer app. To use
// this package, the current Consumer app uses a pre-transpiled stored in
// ujetHeadlessSdkTranspiled.js instead. In the new Consumer app (built with
// React 18 & Next.js) a newer version of Node is used so the
// @ujet/websdk-headless package should be used directly instead.
const UjetHeadlessClientLib = isRunningOnClient
  ? require('./helpers/ujetHeadlessSdkTranspiled')
  : null;

const { Client: UjetHeadlessClient } = UjetHeadlessClientLib || {};

const CONTEXT_KEY = 'useIsomorphicEffectState';

const clientVersion = process.env.REACT_APP_PACKAGE_VERSION;

const disablePopupScriptsIfNeeded = () => {
  const scriptList = [];

  const oneTrustScript = document.querySelector(
    "script[src*='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js?did=']"
  );
  scriptList.push(oneTrustScript);

  const digiohScripts = document.querySelectorAll(
    "script[src*='www.lightboxcdn.com/vendor/']"
  );
  scriptList.push(...digiohScripts);

  if (disablePopups() === true) {
    scriptList?.forEach(script => script?.remove());
  }
};

class App extends React.Component {
  constructor(props) {
    super(props);

    const { staticContext, client, setAuthTokenAndUser } = props;

    const { cookies } = props;
    let bootstrapped = false;
    setHttpContextValue(CONTEXT_KEY, null);

    if (props.initData) {
      let finalInitData = { initData: props.initData };

      bootstrap.init(
        finalInitData,
        props.client,
        props.cookies,
        setAuthTokenAndUser
      );

      bootstrapped = true;
    }

    this.state = {
      error: false,
      bootstrapped,
      isTradeUser: false,
      isLuxInitSet: false,
      isChatInitialized: false,
    };

    //Only run bootstrap from constructor when rendering on server
    if (staticContext && Object.keys(staticContext).length) {
      bootstrap.init(staticContext, client, cookies, setAuthTokenAndUser);
    }

    const contxt = this;

    bootstrap.once('initialized', function(val) {
      if (!contxt.state.bootstrapped) {
        contxt.setState({
          bootstrapped: true,
        });
      }
    });

    this.previousLink = '';
  }

  componentDidMount() {
    initTalkativeCustomConfig({
      onTalkativeEnterInteraction: this.onTalkativeEnterInteraction,
    });

    const { initData = null, setAuthTokenAndUser } = this.props;

    this.unlistenHistory = this.props.history.listen(
      async (location, action) => {
        await this.sendTrackingData(undefined, location, this.previousPath);
        this.previousLink = `${window.location.origin}${this.props.location.pathname}`;
        this.previousPath = location.pathname;
      }
    );

    //Initial page view
    if (!initData) {
      bootstrap
        .init(
          { initData },
          this.props.client,
          this.props.cookies,
          setAuthTokenAndUser
        )
        .then(() => {
          this.setState({ bootstrapped: true });
          this.sendTrackingData(true);
        });
    }

    if (this.state?.bootstrapped) {
      this.sendTrackingData();
    }

    disablePopupScriptsIfNeeded();
  }

  componentWillUnmount() {
    this.unlistenHistory();
  }

  // Start CS chat and run callback. If CS chat is already started,
  // just run callback
  startCSChat = (callback = () => {}) => {
    // Add required logic to initialize the chat app
    callback();
  };

  getUJETAuthToken = async () => {
    const authResponse = await apolloClient.query({
      query: GET_UJET_AUTH,
    });
    return authResponse.data.getUJETAuthentication;
  };

  getIsUJETAgentActive = async () => {
    const ujetHeadlessClient = new UjetHeadlessClient({
      authenticate: this.getUJETAuthToken,
      companyId: getEnvValue('REACT_APP_UJET_COMPANY_ID'),
      tenant: 'joybird',
    });

    const ujetMenus = await ujetHeadlessClient.getMenus();

    const customerServiceItem = ujetMenus?.menus?.find(
      ({ name }) => name === 'Customer Service'
    );

    if (!customerServiceItem) {
      return false;
    }

    if (
      !customerServiceItem.deflection &&
      customerServiceItem.channels?.some(
        channel => channel?.enabled && !channel?.deflected
      )
    ) {
      return true;
    }

    return false;
  };

  initUjetChatConfig = () => {
    if (!window.ujetInstance) {
      window.ujetInstance = new window.UJET({
        companyId: getEnvValue('REACT_APP_UJET_COMPANY_ID'),
        authenticate: this.getUJETAuthToken,
        host: getEnvValue('REACT_APP_UJET_API_HOST'),
        logo: 'https://joybird2.imgix.net/birdy.png',
        right: '14px',
        bottom: '96px',
        translation: {
          en: {
            ujet_start_title: '',
            ujet_greeting: '',
            ujet_message_queue: '',
            ujet_channel_chat: '',
            ujet_message_chat_start: '',
          },
        },
        style: {
          '--primary-font': 'interstate, Roboto, sans-serif',
          '--primary-color': '#016A78',
          '--link-color': '#016A78',
        },
        launcher: {
          right: '14px',
          bottom: '14px',
          closeIcon: 'https://joybird2.imgix.net/closeIcon3x.png',
          style: {
            '--background-color': '#107c8c',
            '--icon-color': '#ffffff',
          },
        },
      });

      window.ujetInstance.registerHook('close', () => {
        showElement('#hero-iframe-container');
        hideElement('#ujet-launcher');
      });
    }
  };

  getFirstMessageFromTalkative = () => {
    const latestInteraction = window.talkativeApi.interaction.getInteraction();
    const firstMessage = latestInteraction?.messages?.[0]?.message;

    return firstMessage;
  };

  onTalkativeEnterInteraction = () => {
    // When an interaction starts, we want to check if this is the Customer
    // Support bot. To do this, we poll the chat for the first message, and
    // check if it contains "Launching Customer Support".
    poll({
      condition: () =>
        window.talkativeApi &&
        this.getFirstMessageFromTalkative()?.includes(
          'Launching Customer Support'
        ),
      callback: () => {
        // If "Customer Support" was triggered
        window.talkativeApi.ui.hide();

        if (window?.ujetInstance && this.state.isUJETAgentAvailable) {
          // If UJET widget is active, open it
          hideElement('#talkative-engage');
          displayBlockElement('#ujet-launcher');
          window.ujetInstance.open();
        } else {
          // Otherwise go to the Contact Us page.
          this.props.history.push('/contact-us/');
          hideElement('#talkative-engage');
        }
      },
      interval: 50,
      maxRetries: 200,
    });
  };

  initChatIfRequired = () => {
    if (isUjetChatEnabled()) {
      this.getIsUJETAgentActive().then(isUJETAgentAvailable => {
        this.setState({ isUJETAgentAvailable });
      });

      loadUJETScript();

      poll({
        condition: () => !!window?.UJET && this.state.isUJETAgentAvailable,
        callback: this.initUjetChatConfig,
      });
    }

    if (isTalkativeChatEnabled() && !disablePopups()) {
      loadTalkativeScript();
    }

    if (isUjetChatEnabled() && !isTalkativeChatEnabled()) {
      poll({
        condition: () =>
          !!window.ujetInstance && this.state.isUJETAgentAvailable,
        callback: () => {
          // Show UJET launcher
          displayBlockElement('#ujet-launcher');
          window.ujetInstance.open();
        },
      });
    }
  };

  async sendTrackingData(
    isInitialPageView = false,
    location = {},
    previousPath = ''
  ) {
    //Only work in the browser
    if (!isClient()) return;

    if (this.previousLink) {
      markLuxPageNavigationStart();
    }

    // get user info
    const accountResponse = await apolloClient.query({
      query: GET_ACCOUNT_INFO,
      options: {
        fetchPolicy: 'cache-and-network',
      },
    });

    const accountInfo = get(accountResponse, 'data.getAccountInfo') || {};
    const isTradeUser = !!accountInfo.is_designer;

    this.setState({ isTradeUser });

    if (isClient() && !isTradeUser && !this.state.isChatInitialized) {
      setTimeout(() => {
        this.initChatIfRequired();
      }, 0);
    }

    const routeInfo = getRouteInfo();
    const pageType = getPageType(routeInfo);

    if (pageType === 'product') {
      const interval = setInterval(() => {
        if (
          window.document.location.href
            .toLowerCase()
            .includes(window.document.title.split(' ')[0].toLowerCase())
        ) {
          if (window.location.hash !== '#modal') {
            if (isInitialPageView) {
              logPageViewEvent(pageType);
            } else if (
              !isInitialPageView &&
              previousPath !== location?.pathname
            ) {
              logPageViewEvent(pageType);
            }
          }
          clearInterval(interval);
          return;
        }
      }, 30);
    } else if (pageType === 'home') {
      setLuxPageLabel(LUX_PAGE_LABELS.HOME);
      logPageViewEvent(pageType);
    } else if (
      pageType === 'category' ||
      pageType === 'swatches' ||
      pageType === 'Shop by Color'
    ) {
      setTimeout(() => {
        if (isInitialPageView) {
          logPageViewEvent(pageType);
        } else if (!isInitialPageView && previousPath !== location?.pathname) {
          logPageViewEvent(pageType);
        }
      }, 0);
    } else if (pageType === 'customerPhotos') {
      setTimeout(() => {
        if (isInitialPageView) {
          logPageViewEvent(pageType);
        } else if (!isInitialPageView && previousPath !== location?.pathname) {
          logPageViewEvent(pageType);
        }
      }, 0);
    } else {
      setTimeout(() => {
        logPageViewEvent(pageType);
      }, 0);
    }

    setCJAffiliateCookie();
    // because of this being an SPA document.referrer doesn't get updated
    // To mitigate this problem we use our own previousLink to put into GA
    setTimeout(() => {
      window.previousLink = this.previousLink;
    }, 500);
  }

  render() {
    //Only show loader on the client
    if (!this.state.bootstrapped && !this.state.error && isClient()) {
      return <GenericLoader />;
    }

    // Throw error only in browser
    if (this.state.error && isClient()) {
      throw this.state.error;
    }

    return (
      <div className="App">
        <Helmet>
          <meta property="og:site_name" content="Joybird" />
          <meta name="twitter:site" content="@wearejoybird" />
          <meta name="client-version" content={clientVersion} />
        </Helmet>

        <SkipToContentLink />

        {isIE() && <IEMsg />}

        <Switch>
          <Route
            exact
            strict
            path="/search"
            render={props => {
              return (
                //Handle cases where search does not recognize query params
                <Redirect
                  to={
                    props.location.pathname.includes('?')
                      ? props.location.pathname
                      : `${props.location.pathname}/${props.location.search}${props.location.hash}`
                  }
                />
              );
            }}
          />
          {[
            ...routes.reactRedirectRoutes,
            ...(routes.reactPrintCampaignRedirectRoutes || []),
            ...routes.reactRoutes,
          ]}
        </Switch>
        <PurgeUserCache />
        <ExposeHistory />
      </div>
    );
  }
}

export default compose(
  withApollo,
  withRouter,
  withCookies,
  withSetAuthTokenAndUser
)(App);
