const disablePopups = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const disablePopupsValue = urlParams.get('disablePopups');
  if (disablePopupsValue !== null) {
    if (disablePopupsValue?.toLowerCase() === 'true') return true;
    if (disablePopupsValue?.toLowerCase() === 'false') return false;
    console.error(
      `Value Error:\n"${disablePopupsValue}" is not a valid value for disablePopups\nPlease enter a different value or remove parameter from URL`
    );
  }
  if (/dev|stg|test|feature/g.test(window.location.hostname)) {
    return true;
  }
  return false;
};

export default disablePopups;
